import {Injectable} from '@angular/core';

//import * as jwt_decode from 'jwt-decode';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map, mergeMap, Observable, tap, throwError} from 'rxjs';


import {LoginCredentials, LoginResponse, User} from "./data-interfaces";
import {SessionManagerService} from "./session-manager.service";
import {environment} from "../../environments/environment";


interface DaoWhereCondition {
    field: string;
    operator: string;
    value: string | number | boolean;
    model: string;
    followingOperator?: string;
}

export interface RequestManagerWhereCondition {
    [s: string]: any;
}

/**
 *
 * Request manager to handle, that handles all Requests to the backend and saves information about backend server and cookies
 * It's possible to add where conditions to GET-requests
 * Available request types:
 * GET, POST, PUT, DELETE
 */
@Injectable({
    providedIn: 'root',
})
export class RequestManagerService {
  private baseURL = ''; // TODO: unset this variable

  private token = '';

  constructor(
    private http: HttpClient,
    private sessionManager: SessionManagerService
  ) {

  }


  getBasePath() {
    return "https://" + environment.baseURL + "/index.php/";
  }

  public auth(body: LoginCredentials): Observable<any> {
    this.setBaseUrl(this.getInstanceUrl(body.url + '/index.php'));

    return this.http.post<LoginResponse>(this.baseURL + 'auth/', body).pipe(
      mergeMap((loginResponse: LoginResponse) => {
        this.sessionManager.setToken(loginResponse);
        return this.getForInstance('user/self').pipe(
          tap((userData: User) => {
            this.sessionManager.setSession(loginResponse, this.baseURL, userData);
          })
        )
      }),
      catchError((error: any) => {
        this.sessionManager.unsetSession();
        console.log(error);
        return throwError(error.status ?? 500);
      })
    );
  }

  public passwordForgetRequest(email: string, url: string): Observable<any> {
    this.setBaseUrl(this.getInstanceUrl(url + '/index.php'));

    return this.http.post<any>(this.baseURL + 'users/password-forgot', {email: email});
  }

  public authRefresh(token: string): Observable<any> {

    const body = {refreshToken: token};
    return this.http.post<LoginResponse>(this.baseURL + 'auth/refresh', body).pipe(
      mergeMap((loginResponse: LoginResponse) => {
        this.sessionManager.setToken(loginResponse);
        return this.getForInstance('user/self').pipe(
          tap((userData: User) => {
            this.sessionManager.setSession(loginResponse, this.baseURL, userData);
          }),
          map(() => {
            return loginResponse['accessToken'];
          })
        )
      }),
      catchError((error: any) => {
        return throwError(error.status ?? 500);
      })
    );
  }

  setBaseUrlAbsolute(url: string) {
    this.baseURL = url;
  }

  setBaseUrl(url: string) {
    this.baseURL = url + '.' + environment.baseURL + '/index.php/';
    console.log(this.baseURL);
  }

  getInstanceUrl(instanceUrl: string) {

    const instanceParts = instanceUrl.split('.');

    return 'https://' + instanceParts[0];

  }

  getForInstance(route: string, parameters: { where_condition?: any } = {}, whereConditions: RequestManagerWhereCondition | null = null, responseType = 'json'): Observable<any> {
    if (whereConditions) {
      if (!parameters) {
        parameters = {};
      }


      parameters['where_condition'] = this.buildEqualsWhereConditions(whereConditions);
    }

    const options: any = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: parameters,
      responseType: responseType
    };

    return this.http.get(this.baseURL + route, options);
    }


    buildEqualsWhereConditions(whereConditions: RequestManagerWhereCondition) {
        const DaoWhereConditions: DaoWhereCondition[] = [];

        const conditions = Object.keys(whereConditions),
            totalConditions = conditions.length;

        conditions.forEach((key) => {
            const [DaoModel, DaoField] = key.split('.');
            const DaoWhereCondition = {
                field: DaoField,
                operator: '=',
                value: `'${whereConditions[key]}'`,
                model: DaoModel,
                followingOperator: DaoWhereConditions.length < totalConditions - 1 ? 'AND' : '',
            };
            DaoWhereConditions.push(DaoWhereCondition);
        });

        return JSON.stringify(DaoWhereConditions);
    }

    /**
     * GET-Request from route with parameters
     * @param route route endpoint
     * @param parameters parameters that can be passed e.g. exclude fields etc.
     * @param whereConditions whereCondition for the query
     */
    get(route: string, parameters: any = null, whereConditions: RequestManagerWhereCondition | null = null): Observable<any> {

        if (whereConditions) {
            if (!parameters) {
                parameters = {};
            }


          parameters['where_condition'] = this.buildEqualsWhereConditions(whereConditions);
        }

      const options = {
        headers: new HttpHeaders({
          Authorization: this.token,
        }),
        params: parameters,
      };

      const url = window.location.toString()
        .replace("https://", "")
        .replace("http://", "")
        .split("/")[0];


      /*
     * for debugging
     */
      if (url.includes('localhost')) {
        this.setBaseUrl(this.getInstanceUrl('hagena.st3uern.riecken.io'));
      }


      return this.http.get(this.baseURL + route, options);
    }


  postForURL(url: string, body: any, parameters: any = {}): Observable<any> {
    const options = {
      headers: new HttpHeaders({}),
      params: parameters,
    };
    return this.http.post(url, body, options);
  }


  /**
   * POST-Request from route with body
   * @param route route endpoint
   * @param body body that contains data for backend e.g. IEncryptedMessage.ts.
   */
  post(route: string, body: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };

    return this.http.post(this.baseURL + route, body, options);
  }

    /**
     * PUT-Request from route with body
     * @param route route endpoint
     * @param body body that contains data for backend e.g. IEncryptedMessage.ts.
     */
    put(route: string, body: any,): Observable<any> {


      const options = {
        headers: new HttpHeaders({
          Authorization: this.token,
        }),
      };

      return this.http.put(this.baseURL + route, body, options);

    }

  /**
   * DELETE-Request from route
   * @param route route that shows what should be deleted
   */
  delete(route: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };

    return this.http.delete(this.baseURL + route, options);
  }

}
