import {Injectable} from '@angular/core';
import {RequestManagerService} from "./request-manager.service";
import {Colors} from "./colors";
import {map, Observable, of, Subject, take, tap} from "rxjs";
import {NotifierService} from "angular-notifier";


@Injectable({
  providedIn: 'root',
})
export class ColorsService {

  private colorsSubject = new Subject<Colors>();

  readonly colors$ = this.colorsSubject.asObservable();

  constructor(private requestManager: RequestManagerService,
              private notifier: NotifierService) {
    this.loadColors(true)
      .pipe(
        take(1)
      )
      .subscribe();
  }

  public loadColors(updateColors = false, subscribe = false): Observable<Colors | null> {
    const observable = this.requestManager.get('/general/settings/colors/', true)
      .pipe(
        take(1),
        map((colorSettings) => {
          const colors: Colors = {
            primaryColor: colorSettings.primaryColor,
            secondaryColor: colorSettings.secondaryColor,
          };
          this.colorsSubject.next(colors);

          return colors;
        }),
        tap((colors) => {
          if (updateColors) {
            this.updateColorVariables(colors);
          }
        })
      );

    if (subscribe) {
      observable.subscribe();
      return of(null);
    }

    return observable;

  }

  public updateColors(primaryColor: string, secondaryColor: string) {
    const body = {primaryColor: primaryColor, secondaryColor: secondaryColor};
    this.requestManager.put('general/settings/colors/', body)
      .pipe(take(1),
        tap((response) => {
          this.colorsSubject.next(body);
        }),
        tap((response) => {
          this.updateColorVariables(body);
        }),
      )
      .subscribe(() => {
        },
        () => {
          this.notifier.notify('error', 'Es gab einen Fehler beim Aktualisieren der Farben!');
        });
  }

  private updateColorVariables(colors: Colors) {
    console.log(colors);
    const root = document.documentElement;

    let primaryColor: any = this.getColorRgba(colors.primaryColor);
    let rgbPrimaryButtonColor: any = this.getColorRgba(colors.secondaryColor);

    root.style.setProperty('--primary-color', `rgba(${primaryColor.r},${primaryColor.g},${primaryColor.b},${primaryColor.a})`);

    if (rgbPrimaryButtonColor) {
      root.style.setProperty('--primary-color-disabled', `rgba(${rgbPrimaryButtonColor.r},${rgbPrimaryButtonColor.g},${rgbPrimaryButtonColor.b},0.5)`);
      root.style.setProperty('--primary-button-color', `rgb(${rgbPrimaryButtonColor.r},${rgbPrimaryButtonColor.g},${rgbPrimaryButtonColor.b})`);
      root.style.setProperty('--primary-button-color-transparency-054', `rgba(${rgbPrimaryButtonColor.r},${rgbPrimaryButtonColor.g},${rgbPrimaryButtonColor.b},0.54)`);
      root.style.setProperty('--primary-button-color-transparency-025', `rgba(${rgbPrimaryButtonColor.r},${rgbPrimaryButtonColor.g},${rgbPrimaryButtonColor.b},0.25`);

    }

  }

  private hexToRgb(hex: string): { r: number; g: number; b: number, a?: number } | null {
    const regex = /^#?([A-Fa-f\d]{2})([A-Fa-f\d]{2})([A-Fa-f\d]{2})$/;
    const result = regex.exec(hex);

    if (result) {
      return {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      };
    }

    return null;
  }

  private rgbaStringToHex(rgbaString: string, halveAlpha = false): string | null {
    // Extrahiere die Zahlenwerte aus dem RGBA-String
    if (rgbaString !== null) {
      const [r, g, b, a]: any = rgbaString.match(/\d+(\.\d+)?/g)?.map(Number);

      function componentToHex(c: any) {
        const hex = Math.round(c).toString(16);
        return hex.length == 1 ? "0" + hex : hex;
      }

      // Komponenten in Hex umwandeln
      const hexR = componentToHex(r);
      const hexG = componentToHex(g);
      const hexB = componentToHex(b);

      let hexA = '';
      if (a !== undefined) {
        hexA = componentToHex(Math.round(a * 255));
        if (halveAlpha) {
          // Reduziere den Alpha-Wert auf die Hälfte
          const newAlphaDecimal = Math.round(a * 255 * 0.95);
          const newAlphaHex = newAlphaDecimal.toString(16);
          hexA = newAlphaHex.length == 1 ? "0" + newAlphaHex : newAlphaHex;
        }
      }


      // Hex-Farbe zusammensetzen
      return `#${hexR}${hexG}${hexB}${hexA}`;
    }

    return null;
  }

  private hexToRgba(hexColor: string) {
    // Entferne das vorangestellte '#' (falls vorhanden)
    hexColor = hexColor.replace(/^#/, '');

    // Zerlege den Hexadezimalcode in RGB- und Alpha-Komponenten
    const hexR = hexColor.substring(0, 2);
    const hexG = hexColor.substring(2, 4);
    const hexB = hexColor.substring(4, 6);
    const hexA = hexColor.substring(6, 8);

    // Funktion, um eine Hexadezimalzahl in eine Dezimalzahl umzuwandeln
    function hexToDecimal(hex: any) {
      return parseInt(hex, 16);
    }

    // Wandele die Hex-Komponenten in Dezimalwerte um
    const r = hexToDecimal(hexR);
    const g = hexToDecimal(hexG);
    const b = hexToDecimal(hexB);
    const a = hexA !== '' ? hexToDecimal(hexA) / 255 : 1; // Normalisiere Alpha-Wert, falls vorhanden


    return {
      r: r,
      g: g,
      b: b,
      a: a
    };
  }


  private getColorRgba(color: string): { r: number; g: number; b: number, a?: number } {

    let rgbaColor: any;

    if (color.includes('rgba')) {
      color = this.rgbaStringToHex(color) ?? '';
    }

    if (color.length === 7) {
      rgbaColor = this.hexToRgb(color);
      if (rgbaColor) {
        rgbaColor.a = 1;
      }

    } else {
      rgbaColor = this.hexToRgba(color);
    }

    return rgbaColor;
  }


}
