import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {BehaviorSubject} from "rxjs";
import {RequestManagerService} from "../../services/request-manager.service";
import {NotifierService} from "angular-notifier";
import {Router} from "@angular/router";
import {LoginService} from "../../services/login.service";
import {ColorsService} from "../../services/colors.service";


export function customEmailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const email = control.value;
    if (!email) {
      return null; // Wenn das Feld leer ist, kümmert sich der "required"-Validator darum
    }

    // RegEx für eine strengere E-Mail-Validierung
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    return emailRegex.test(email) ? null : {invalidEmail: true};
  };
}

@Component({
  selector: 'app-password-forgot',
  templateUrl: './password-forgot.component.html',
  styleUrls: ['./password-forgot.component.css']
})
export class PasswordForgotComponent implements OnInit {


  emailForm = this.fb.group({
    email: this.fb.control('', [Validators.required, customEmailValidator()]),
  });

  loadingSubmit$ = new BehaviorSubject(false);
  submitted = false;

  constructor(private fb: FormBuilder,
              private requestManager: RequestManagerService,
              private notifier: NotifierService,
              private router: Router,
  ) {
  }

  ngOnInit(): void {
  }

  login() {
    this.loadingSubmit$.next(true);

    let credentials = this.emailForm.value;
    credentials.url = window.location.toString()
      .replace("https://", "")
      .replace("http://", "")
      .split("/")[0];

    if (credentials.url.includes('localhost')) {
      credentials.url = 'hagena.beta.st3uern.riecken.io';
    }

    this.submitted = true;
    this.requestManager.passwordForgetRequest(credentials.email, credentials.url).subscribe(
      (success) => {
        this.loadingSubmit$.next(false);
      },
      (errorCode) => {
        this.loadingSubmit$.next(false);
      }
    );
  }

  onEnterKey() {

    if (!this.emailForm.invalid && !this.loadingSubmit$.value) {
      this.login();
    }
  }

  openLogin() {
    this.router.navigateByUrl('/login');
  }
}
