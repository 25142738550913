import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  filter,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
  debounceTime,
  distinctUntilChanged,
  startWith, Subscription
} from "rxjs";
import {downloadFilterStatus, filterItem, Settings} from "../../../services/data-interfaces";
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";
import {DocumentsListUngroupedComponent} from "../documents-list-ungrouped/documents-list-ungrouped.component";
import {RequestManagerService} from "../../../services/request-manager.service";
import {NotifierService} from "angular-notifier";
import {DocumentFilterService} from "../../../services/document-filter.service";
import {MatMenuTrigger} from '@angular/material/menu';

@Component({
  selector: 'app-documents-list',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.css']
})
export class DocumentsListComponent implements OnInit, OnDestroy {

  outboxes$: Subscription;
  loading$ = new BehaviorSubject(false);

  reload$ = new BehaviorSubject(false);
  limit = 10;
  limitOptions = [5, 10, 25, 50, 100];
  offset = 0;
  nextPageDisabled = false;
  outboxes: any = [];
  mode: 'send' | 'list' = 'list';

  isDownloadedFilter: downloadFilterStatus = 'all';

  taskUnDoneFilter: '1' | '0' = '0';

  dateRangeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const group = control as FormGroup;
    const start = group.get('start')?.value;
    const end = group.get('end')?.value;

    if (start && end && end < start) {
      return {invalidRange: true};
    }

    return null;
  }

  dateRangeFormGroup = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null)
  }, {validators: [this.dateRangeValidator]});

  searchControl = new FormControl('');

  userData$ = new BehaviorSubject(null);
  userClients$: Observable<any>;
  clientSelected = 'all';
  clientSelected$ = new BehaviorSubject<string>(this.clientSelected);

  currentDate: Date | null = null;


  orderDocuments$ = new BehaviorSubject({orderBy: 'outboxCreated', order: 'DESC'});

  @ViewChild(DocumentsListUngroupedComponent, {static: false}) documentsListComponent: DocumentsListUngroupedComponent | null = null;
  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;

  settings: Settings | null = null;
  contactLink: string | null = null;


  predefinedRanges = [
    {label: 'Beliebig', value: 'all'},
    {label: '1 Tag', value: '1d'},
    {label: '1 Woche', value: '1w'},
    {label: '1 Monat', value: '1m'},
    {label: '3 Monate', value: '3m'},
    {label: '1 Jahr', value: '1y'},
    {label: 'Benutzerdefiniert', value: 'custom'}
  ];

  selectedRangeControl = new FormControl('all');

  constructor(private requestManager: RequestManagerService,
              private notifier: NotifierService,
              private filterService: DocumentFilterService
  ) {

    this.loading$.next(true);

    this.requestManager.get('dms/settings/')
      .pipe(
        take(1)
      ).subscribe((settings) => {
      this.settings = settings;
      this.contactLink = settings.contactLink;
    });

    this.currentDate = new Date();

    const startDate = new Date(2000, 0, 1);

    this.dateRangeFormGroup.setValue(
      {
        start: startDate,
        end: this.currentDate,
      });

    this.requestManager.getForInstance('user/self').pipe(
      take(1),
      tap((userData: any) => {
        this.userData$.next((userData));
      })
    ).subscribe();

    this.userClients$ = this.userData$.pipe(
      filter((userData: any) => userData !== null),
      take(1),
      switchMap((userData) => {
        return this.requestManager.getForInstance('users/' + userData.id + '/userClients')
          .pipe(
            map((userClients: any) => {
              if (userClients && Array.isArray(userClients)) {
                const clients = [];
                for (const userClient of userClients) {
                  if (userClient.client && Array.isArray(userClient.client)) {
                    for (const client of userClient.client) {
                      clients.push(client);
                    }
                  }
                }
                return clients;
              }
              return [];
            })
          )
      })
    );

    this.outboxes$ = combineLatest([
      this.reload$,
      this.userData$.pipe(filter((userData: any) => userData !== null)),
      this.searchControl.valueChanges.pipe(
        startWith(''), // emit initial value
        debounceTime(300),
        distinctUntilChanged(),
        filter((searchTerm) => searchTerm.length >= 2 || searchTerm.length === 0)
      )
    ])
      .pipe(
        switchMap(([reload, userData, searchTerm]: any) => {
          if (reload) {
            this.loading$.next(true);
            return combineLatest([
              this.filterService.getTaskUndoneFilter(),
              this.filterService.getDownloadedFilter(),
              this.orderDocuments$
            ])
              .pipe(
                switchMap(([taskUndoneFilter, downloadedFilter, orderDocuments]: ['1' | '0', downloadFilterStatus, filterItem]) => {
                  return this.getDocumentsUngrouped(taskUndoneFilter, downloadedFilter, orderDocuments, this.clientSelected, searchTerm);
                }),
                tap((documents) => {
                  // Überprüfen, ob documents tatsächlich ein Objekt ist und Einträge enthält
                  if (documents && typeof documents === 'object') {
                    // Konvertieren des Objekts in ein Array von Schlüssel-Wert-Paaren
                    let entries = Object.entries(documents);

                    // Sortieren des Arrays nach dem commit_date in outboxDetails
                    let sortedEntries = entries.sort((a: any, b: any) => {
                      let dateA = a[1].outboxDetails?.commit_date ? new Date(a[1].outboxDetails.commit_date).getTime() : 0;
                      let dateB = b[1].outboxDetails?.commit_date ? new Date(b[1].outboxDetails.commit_date).getTime() : 0;
                      return dateB - dateA; // Absteigend sortieren
                    });

                    // Konvertieren des sortierten Arrays zurück in ein Objekt
                    let sortedObject = Object.fromEntries(sortedEntries);

                    this.outboxes = sortedEntries.map(entry => entry[1]);

                  } else {
                    console.error('Documents is not a valid object:', documents);
                  }

                  // Setzen von loading$ auf false
                  this.loading$.next(false);
                }),
                map((documents) => {
                  return Object.values(documents);
                })
              );
          }
          return of([]);
        })
      ).subscribe();
  }

  ngOnInit(): void {
    this.reload$.next(true);
  }


  openFilter() {
    this.menuTrigger.openMenu();
  }

  resetFilter() {
    this.loading$.next(true);
    this.filterService.setFilter('0', 'all');
  }

  applyFilter() {
    this.loading$.next(true);
    this.filterService.setFilter(this.taskUnDoneFilter, this.isDownloadedFilter);
  }

  getNextPage() {
    this.offset += this.limit;
    this.reload$.next(true);
  }

  getPreviousPage() {
    this.offset -= this.limit;
    if (this.offset < 0) {
      this.offset = 0;
    }
    this.reload$.next(true);
  }

  reloadDocuments(forceReload = false) {
    if (forceReload) {
      this.reload$.next(true);
    }
  }

  previousPageButtonIsDisabled() {
    if (this.offset === 0) return true;

    return (this.offset - this.limit) < 0;
  }


  getDate(date: any): string {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Monat beginnt bei 0
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  getDocumentsUngrouped(taskUndoneFilter: '1' | '0', downloadedFilter: downloadFilterStatus, orderDocuments: filterItem, clientNumber: any, searchTerm: string): Observable<any> {
    return this.requestManager.getForInstance('outboxes'
      + '?downloaded=' + downloadedFilter
      + '&taskUnDone=' + taskUndoneFilter
      + '&offset=' + this.offset
      + '&limit=' + this.limit
      + '&from=' + (this.dateRangeFormGroup.get('start')?.value ? this.getDate(this.dateRangeFormGroup.get('start')?.value) : this.getDate(this.currentDate))
      + '&to=' + (this.dateRangeFormGroup.get('end')?.value ? this.getDate(this.dateRangeFormGroup.get('end')?.value) : this.getDate(this.currentDate))
      + '&orderBy=' + orderDocuments.orderBy
      + '&order=' + orderDocuments.order
      + '&clientNumber=' + clientNumber
      + (searchTerm ? '&searchTerm=' + searchTerm : '')
    )
      .pipe(
        switchMap((documents) => {
          if (documents.error_description != undefined) {
            this.loading$.next(false);
            this.notifier.notify('error', 'Es gab einen Fehler beim Laden der Dokumente! Melden Sie Ihr Problem bei support@riecken.io!');
            return of([]);
          }

          if (documents.length == 0) {
            this.nextPageDisabled = true;
            return of([]);
          }

          this.nextPageDisabled = false;
          return of(documents);
        }),
        catchError((error) => {
          this.nextPageDisabled = true;
          this.notifier.notify('error', 'Es gab einen Fehler beim Laden der Dokumente! Melden Sie Ihr Problem bei support@riecken.io!');
          this.loading$.next(false);
          return of([]);
        }),
      );
  }

  ngAfterViewChecked(): void {
    this.reloadDocuments();
  }

  changeIsDownloadedFilter(event: any) {
    if (event.checked) {
      this.isDownloadedFilter = 'notDownloaded';
    } else {
      this.isDownloadedFilter = 'all';
    }
  }

  changeTaskUndoneFilter(event: any) {
    if (event.checked) {
      this.taskUnDoneFilter = '1';
    } else {
      this.taskUnDoneFilter = '0';
    }
  }

  onClientSelectedChange(client: any) {
    this.clientSelected = client;
    this.clientSelected$.next(client);
    this.reloadDocuments(true);
  }

  ngOnDestroy() {
    if (this.outboxes$) {
      this.outboxes$.unsubscribe();
    }
  }

  handleModeChange(newMode: 'send' | 'list') {
    this.mode = newMode;
  }

  onPredefinedRangeChange() {
    const today = new Date();
    let start: Date | null = null;

    switch (this.selectedRangeControl.value) {
      case 'all':
        start = new Date(2000, 0, 1);
        break;
      case '1d':
        start = new Date(today);
        start.setDate(today.getDate() - 1);
        break;
      case '1w':
        start = new Date(today);
        start.setDate(today.getDate() - 7);
        break;
      case '1m':
        start = new Date(today);
        start.setMonth(today.getMonth() - 1);
        break;
      case '3m':
        start = new Date(today);
        start.setMonth(today.getMonth() - 3);
        break;
      case '1y':
        start = new Date(today);
        start.setFullYear(today.getFullYear() - 1);
        break;
      case 'custom':
        return; // Benutzerdefiniert – nichts setzen
    }

    this.dateRangeFormGroup.patchValue({
      start: start,
      end: today
    });
  }

}
