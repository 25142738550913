import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from "./components/login/login.component";
import {CreateInstanceComponent} from "./components/admin/create-instance/create-instance.component";
import {OrdersPageComponent} from "./components/orders-page/orders-page.component";
import {BaseDataPageComponent} from "./components/base-data-page/base-data-page.component";
import {AuthGuardService} from "./services/auth-guard.service";
import {AdminDashboardComponent} from "./components/admin-dashboard/admin-dashboard.component";
import {NotFoundComponent} from "./components/not-found/not-found.component";
import {UserApprovalComponent} from "./components/user-approval/user-approval.component";
import {PasswordAssignmentComponent} from "./components/password-assignment/password-assignment.component";
import {DocumentsListComponent} from "./components/documents-page/documents-list/documents-list.component";
import {PasswordForgotComponent} from "./components/password-forgot/password-forgot.component";


export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/documents',
  },
  {
    path: 'login',
    pathMatch: 'full',
    component: LoginComponent,
  },
  {
    path: 'admin/instance/create',
    component: CreateInstanceComponent
  },
  {
    path: 'forgot-password',
    component: PasswordForgotComponent
  },
  {
    path: 'user/:userId/password/:passwordToken',
    component: PasswordAssignmentComponent,
  },
  {
    path: 'documents',
    component: DocumentsListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'orders',
    component: OrdersPageComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: 'base-data',
    component: BaseDataPageComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'user-approval',
    component: UserApprovalComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin',
    component: AdminDashboardComponent,
    canActivate: [AuthGuardService],
  },

  {path: '404', component: NotFoundComponent},
  {path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
