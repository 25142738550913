<mat-card>
  <mat-card-header>
    <div class="mat-card-header-client-list">
      <mat-card-title-group>
        <mat-card-title>
          Hinzugefügte Mandanten
        </mat-card-title>
        <mat-card-subtitle>
          Hier siehst du alle Mandanten, welche du zum Portal hinzugefügt hast
        </mat-card-subtitle>
      </mat-card-title-group>
      <div class="block-pagination-filter">
        <mat-form-field>
          <input
            placeholder="Suche nach Mandanten"
            matInput
            [(ngModel)]="clientFilterText"
            (ngModelChange)="clientFilterTextChanged()"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Max. Mandanten pro Seite</mat-label>
          <mat-select [(ngModel)]="limit" (ngModelChange)="reloadClients()">
            <mat-option *ngFor="let limitOption of limitOptions" [value]="limitOption">
              {{limitOption}}</mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-button color="secondary"
                [disabled]="previousPageButtonIsDisabled()"
                (click)="getPreviousPage()">
          <mat-icon color="secondary">chevron_left</mat-icon>
          Vorherige Seite
        </button>
        <button mat-button
                color="secondary"
                [disabled]="nextPageDisabled"
                (click)="getNextPage()">Nächste Seite
          <mat-icon color="secondary">chevron_right</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content *ngIf="orderClients$ | async as orderClients"
                    class="mat-card-content-list">
    <div class="table-head orderTableGrid">
      <div
        class="orderableTableHeader"
      >
        <mat-card-subtitle class="mat-card-subtitle-table-header">
          Name
        </mat-card-subtitle>
        <!--        <ng-container *ngIf="orderClients.orderBy === 'name'">-->
        <!--          <mat-icon *ngIf="orderClients.order === 'ASC'">-->
        <!--            arrow_upward-->
        <!--          </mat-icon>-->
        <!--          <mat-icon *ngIf="orderClients.order === 'DESC'">-->
        <!--            arrow_downward-->
        <!--          </mat-icon>-->
        <!--        </ng-container>-->

      </div>
      <div class="table-head">
        <mat-card-subtitle>
          UID
        </mat-card-subtitle>
      </div>
      <div
        class="orderableTableHeader table-head"
        (click)="orderBy('clientNumber')">
        <div class="flex">
          <mat-card-subtitle>
            Mandantennummer
          </mat-card-subtitle>
        </div>
        <div *ngIf="orderClients.orderBy === 'clientNumber'">
          <mat-icon *ngIf="orderClients.order === 'ASC'">
            arrow_upward
          </mat-icon>
          <mat-icon *ngIf="orderClients.order === 'DESC'">
            arrow_downward
          </mat-icon>
        </div>
      </div>
      <div class="table-head">
        <mat-card-subtitle>
          Aktiv
        </mat-card-subtitle>
      </div>
    </div>
    <ng-container *ngIf="(addedClients$ | async) as addedClients">

      <div *ngFor="let addedClient of addedClients" class="orderTableGrid table-row">
        <mat-divider></mat-divider>
        <div class="table-row-align-middle">
          {{addedClient.name ?? '-'}}
        </div>
        <div class="table-row-align-middle">
          {{ addedClient.vatId ?? '-'}}
        </div>
        <div class=" table-row-align-middle">
          {{addedClient.clientNumber ?? '-'}}
        </div>

        <div class="table-row-align-middle">
          {{ addedClient.active == '1' ? 'Ja' : 'Nein'}}
        </div>
      </div>
      <div
        *ngIf="!addedClients || addedClients.length === 0"
        class="block-no-clients">
        Keine Mandanten!
      </div>
    </ng-container>

  </mat-card-content>
</mat-card>


<mat-card class="card-margin">
  <mat-card-header id="mat-card-header-client-add">
    <mat-card-title-group>
      <mat-card-title>
        Mandanten hinzufügen
      </mat-card-title>
      <mat-card-subtitle>
        Füge einen Mandanten hinzu, für diesen Dokumente und Postausgangsätze synchronisiert werden können
      </mat-card-subtitle>
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content [formGroup]="addClientForm"
                    class="mat-card-content-add">
    <mat-form-field appearance="fill" class="select-add-client">

      <mat-label>Mandaten zum Hinzufügen</mat-label>
      <mat-select formControlName="clientId">
        <mat-option>
          <ngx-mat-select-search
            formControlName="clientsSearch"
            placeholderLabel="Mandantenname oder Nummer eingeben "
          ></ngx-mat-select-search>
        </mat-option>
        <ng-container *ngIf="(addableClients$ | async) as addableClients ">
          <mat-option *ngFor="let addableClient of filteredAddableClients" value="{{addableClient.id}}">
            {{addableClient.number + ': ' + addableClient.name}}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </mat-card-content>
  <mat-card-footer>
    <button mat-raised-button
            color="primary"
            class="btn-add-client primary-button-background-color"
            (click)="addClient()"
            [disabled]="addClientForm.invalid || (loadingAddClient$ | async) === true">
      Mandant hinzufügen
    </button>
    <mat-spinner class="spinner-loading-add-client"
                 *ngIf="(loadingAddClient$ | async) === true"
                 [diameter]="25">
    </mat-spinner>
  </mat-card-footer>
</mat-card>
