<div class="container-form">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        Passwort vergessen?
      </mat-card-title>
    </mat-card-header>
    <mat-divider [inset]="true"></mat-divider>
    <mat-card-content>
      <div></div>
      <div *ngIf="submitted" class="text-center">
        <br/>
        <br/>
        Eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts wurde an {{this.emailForm.value.email}} gesendet.
        <br/>Bitte überprüfen Sie Ihren Posteingang und folgen Sie den Anweisungen in der E-Mail.
        <br/>
        <br/>
        Wenn Sie die E-Mail nicht sehen können, überprüfen Sie bitte auch Ihren Spam-Ordner
      </div>
      <div [formGroup]="emailForm" class="form" *ngIf="!submitted">

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>E-Mail</mat-label>
          <input
            matInput
            formControlName="email"
            (keyup.enter)="onEnterKey()"/>
          <mat-error *ngIf="emailForm.get('email')?.hasError('required')">
            Muss ausgefüllt werden
          </mat-error>
          <mat-error *ngIf="emailForm.get('email')?.hasError('invalidEmail')">
            Ungültige E-Mail-Adresse
          </mat-error>
        </mat-form-field>

      </div>
    </mat-card-content>
    <mat-card-footer class="mat-card-footer-submit">
      <mat-spinner
        *ngIf="(loadingSubmit$ | async) === true"
        [diameter]="25">
      </mat-spinner>
      <button
        mat-button
        color="primary"
        (click)="openLogin()">
        Zurück zum Login
      </button>
      <button mat-raised-button *ngIf="!submitted"
              class="button-primary"
              color="primary"
              [disabled]="emailForm.invalid || (loadingSubmit$ | async) === true"
              (click)="login()">Passwort zurücksetzen
      </button>
    </mat-card-footer>
  </mat-card>
</div>
