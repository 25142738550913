import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {RequestManagerService} from "../../services/request-manager.service";
import {BehaviorSubject} from "rxjs";
import {NotifierService} from "angular-notifier";
import {Route, Router} from "@angular/router";
import {LoginService} from "../../services/login.service";
import {ColorsService} from "../../services/colors.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  hide = true;

  loginForm = this.fb.group({
    username: this.fb.control('', [Validators.required]),
    password: this.fb.control('', [Validators.required]),
  });

  loadingSubmit$ = new BehaviorSubject(false);

  constructor(private fb: FormBuilder,
              private requestManager: RequestManagerService,
              private notifier: NotifierService,
              private router: Router,
              private loginService: LoginService,
              private colorService: ColorsService) {
  }

  ngOnInit(): void {
  }

  login() {
    this.loadingSubmit$.next(true);

    let credentials = this.loginForm.value;
    credentials.url = window.location.toString()
      .replace("https://", "")
      .replace("http://", "")
      .split("/")[0];

    if (credentials.url.includes('localhost')) {
      credentials.url = 'hagena.st3uern.riecken.io';
    }
    credentials.username = credentials.username.toString().toLowerCase();

    this.requestManager.auth(credentials).subscribe(
      (success) => {
        this.loadingSubmit$.next(false);
        this.loginService.setIsLoggedIn(true);
        console.log(success);
        this.colorService.loadColors(true, true);
        this.router.navigateByUrl('/documents');
      },
      (errorCode) => {
        console.log(errorCode);
        this.loadingSubmit$.next(false);
        if (errorCode === 401) {
          this.notifier.notify('error', 'Ihre Zugangsdaten sind ungültig!');
        } else {
          this.notifier.notify('error', 'Es gab einen Authentifizierungsfehler! Melden Sie Ihr Problem bei support@riecken.io!');
        }
      }
    );
  }

  onEnterKey() {

    if (!this.loginForm.invalid && !this.loadingSubmit$.value) {
      this.login();
    }
  }

  openPasswordForgotPage() {
    this.router.navigateByUrl('/forgot-password');
  }
}
