<div id="matCard-wrapper" class="margin-card">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        Passwort vergeben
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div *ngIf="userClients && !passwordUpdated">
        <p>Ihr Benutzerkonto wird mit folgenden Mandanten verbunden:</p>
        <ul>
          <li *ngFor="let userClient of userClients">
            {{ userClient.name}}
          </li>
        </ul>
        <p>
          Geben Sie die Mandantennummer für einen dieser Mandanten an,<br/> damit Sie Ihr Passwort erfolgreich vergeben
          können.
        </p>
      </div>
      <div *ngIf="!errorGetUser">
        <ng-container *ngIf="passwordUpdated === false; else passwordUpdatedTemplate">
          <div [formGroup]="passwordForm" id="passwordForm">

            <mat-form-field appearance="fill">

              <mat-label>Eingabe Mandantennummer</mat-label>
              <input matInput
                     data-1p-ignore
                     data-form-type="other"
                     autocomplete="off"
                     type="text"
                     formControlName="clientNumber"
                     readonly
                     onfocus="if (this.hasAttribute('readonly')) {
    this.removeAttribute('readonly');
    // fix for mobile safari to show virtual keyboard
    this.blur();    this.focus();  }"/>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Benutzername</mat-label>
              <input matInput
                     readonly="true"
                     type="text"
                     formControlName="email"
              />
            </mat-form-field>


            <mat-form-field appearance="fill">
              <mat-label>Passwort</mat-label>
              <input matInput
                     type="password"
                     formControlName="password"/>
              <mat-error>
                Das Passwort benötigt mindestens 8 Zeichen, Groß- und Kleinschreibung sowie Sonderzeichen und Zahlen.
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Passwort wiederholen</mat-label>
              <input matInput
                     type="password"
                     formControlName="passwordRepeat"/>
              <mat-error>
                Das Passwort benötigt mindestens 8 Zeichen, Groß- und Kleinschreibung sowie Sonderzeichen und Zahlen.
              </mat-error>
            </mat-form-field>

            <div class="block-button-submit">
              <button mat-raised-button
                      class="button-primary"
                      type="submit"
                      color="primary"
                      [disabled]="!passwordForm.valid
    || (submitLoading$ | async) === true"
                      (click)="submit()">Bestätigen
              </button>
              <mat-spinner class=""
                           *ngIf="(submitLoading$ | async) === true"
                           [diameter]="25">
              </mat-spinner>
            </div>
          </div>
        </ng-container>
      </div>

      <ng-template #passwordUpdatedTemplate>
        <div class="block-password-updated">
          Passwort wurde erfolgreich gesetzt!<br/>
          Zusammen mit der E-Mail können Sie
          sich ab sofort in Ihrem persönlichen<br/>
          <a [href]="frontendLoginUrl">Serviceportal einloggen</a>.
        </div>
      </ng-template>


      <div class="block-password-updated" *ngIf="errorGetUser">
        Es gab einen Fehler beim Abrufen der Informationen.<br/>
        Möglicherweise wurde das Passwort für den Benutzer schon vergeben oder der Link ist abgelaufen.
      </div>
    </mat-card-content>
    <mat-card-footer></mat-card-footer>
  </mat-card>
</div>
